<template>
  <CRow>
    <CCol md="12" class="text-left">
      <CButton class="mb-3" color="success" @click="showCreateModal()">
        Neue Aktivität hinzufügen
      </CButton>
    </CCol>

    <CCol md="12">
      <CTabs :active-tab="activeTab" @update:activeTab="hideModalOnCalendar">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-grid"/>
          </template>
          <br>
          <CDataTable
            :items="tableData"
            :itemsPerPageSelect="{ label: 'Aktivitäten pro Seite' }"
            :fields="getTableFields()"
            :noItemsView="{ noResults: 'Keine Suchergebnisse verfügbar', 
            noItems: 'Keine Aktivitäten vorhanden' }"
            hover
            striped
            bordered
            fixed
            column-filter
            table-filter
            :tableFilter="{ label: 'Aktivität suchen', placeholder: 'Suchbegriff eingeben' }"
            items-per-page-select
            sorter
            pagination
            :loading="tableShowLoading"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  @click="showUpdateModal(item)"
                >
                  Editieren
                </CButton>
              </td>
            </template>
            <template #delete_item="{item, index}">
              <td class="py-2">
                <CButton
                  color="danger"
                  variant="outline"
                  square
                  size="sm"
                  @click="deleteTask(item)"
                >
                  Löschen
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CTab>

        <CTab>
          <template slot="title">
            <CIcon name="cil-calendar"/>
          </template>
          <br>
          <TaskCalendar
            ref="calendar"
            :show-create-modal-with-data="showCreateModalWithData"
            :add-brief-activity="addBriefActivity"
            :show-update-modal="showUpdateModal"
            :delete-activity="deleteTask"
            :apply-parent-update="applyUpdate"
          />
        </CTab>
      </CTabs>
    </CCol>

    <CCol md="12">
      <CModal
        title="Aktivität planen"
        size="lg"
        :show.sync="visibleActivityModal"
        :closeOnBackdrop="false"
      >
        <ActivityWidget
          ref="activityWidget"
          :applyUpdate="applyUpdate"
          :closeModal="closeModal"
        />
        <div slot="footer">
          <CButton
            class="mr-3"
            color="dark"
            variant="outline"
            @click="visibleActivityModal = false"
          >
            Abbrechen
          </CButton>
          <CButton
            color="success"
            v-on:click="submit()"
          >
            Speichern
          </CButton>
        </div>
      </CModal>
    </CCol>
  </CRow>
</template>

<script>
  import ActivityWidget from "./modal/ActivityWidget";
  import axios from "axios";
  import commons from "../../commons";
  import {activityTypes} from "../../config/constant";
  import moment from 'moment'
  import TaskCalendar from "./TaskCalendar";

  export default {
    name: 'TaskWidget',
    components: {
      TaskCalendar,
      ActivityWidget,
    },
    mounted() {
      this.loadTasks()
    },
    data: () => ({
      visibleActivityModal: false,
      tableShowLoading: false,
      tableData: [],
      selectedTaskId: null,
      activeTab: 0,
    }),
    methods: {
      submit() {
        this.$refs.activityWidget.submit(this.selectedTaskId)
      },
      closeModal() {
        this.$refs.activityWidget.initialize()
        this.visibleActivityModal = false
      },
      getTableFields() {
        return [
          {
            key: 'title',
            label: 'Betreff',
          },
          {
            key: 'typeText',
            label: 'Kontaktart',
          },
          {
            key: 'startTime',
            label: 'Fälligkeitstermin von',
          },
          {
            key: 'endTime',
            label: 'Fälligkeitstermin bis'
          },
          {
            key: 'doneStr',
            label: 'Als erledigt markiert?'
          },
          {
            key: 'show_details',
            label: '',
            _style: 'width:1%',
            sorter: false,
            filter: false
          },
          {
            key: 'delete_item',
            label: '',
            _style: 'width:1%',
            sorter: false,
            filter: false
          }
        ]
      },
      loadTasks() {
        const leadId = this.$route.params.id
        let endpoint = ``
        if (leadId) { // if this is in lead detail view
          endpoint = `task/lead/${leadId}`
        } else { // this is in dashboard tasks page
          endpoint = `task/realm`
        }

        this.tableShowLoading = true
        axios.get(endpoint)
          .then((response) => {
            let content = []
            response.data.forEach(value => {
              const selectedActivity = activityTypes.find(item => item.value === value['type'])
              value['typeText'] = selectedActivity.text
              value['startTimeObj'] = commons.formatStandardDate(new Date(value['startTime']))
              value['endTimeObj'] = commons.formatStandardDate(new Date(value['endTime']))
              moment.locale('de')
              value['startTime'] = moment(value['startTime']).format('LLL')
              value['endTime'] = moment(value['endTime']).format('LLL')
              value['doneStr'] = value['done'] ? 'Yes' : 'No'
              content.push(commons.flatObject(value))
            })
            this.tableData = content
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.tableShowLoading = false
          })
      },
      // after success the create or update activity, also update the table data
      applyUpdate(data) {
        const leadId = this.$route.params.id
        const index = this.tableData.findIndex(entity => entity.id === data.id)

        data['startTimeObj'] = commons.formatStandardDate(new Date(data['startTime']))
        data['endTimeObj'] = commons.formatStandardDate(new Date(data['endTime']))
        moment.locale('de')
        data['startTime'] = moment(data['startTime']).format('LLL')
        data['endTime'] = moment(data['endTime']).format('LLL')
        data['doneStr'] = data['done'] ? 'Yes' : 'No'
        const selectedActivity = activityTypes.find(item => item.value === data['type'])
        data['typeText'] = selectedActivity.text

        if (leadId) { // if this is in lead detail
          if (data['lead'] && (parseInt(data['lead'].id) === parseInt(leadId))) { // when task belongs to this lead
            // if created
            if (index === -1) {
              if (this.tableData.length === 0) {
                this.tableData = [commons.flatObject(data)]
              } else {
                this.tableData.push(commons.flatObject(data))
              }
            } else {
              this.tableData.splice(index, 1, commons.flatObject(data))
            }
          } else {
            this.tableData.splice(index, 1)
          }
        } else {
          if (index === -1) { // if task created
            if (this.tableData.length === 0) {
              this.tableData = [commons.flatObject(data)]
            } else {
              this.tableData.push(commons.flatObject(data))
            }
          } else {
            this.tableData.splice(index, 1, commons.flatObject(data))
          }
        }

        this.$refs.calendar.applyUpdate(data)
      },
      showCreateModal() {
        this.selectedTaskId = null
        this.visibleActivityModal = true
        this.$refs.activityWidget.initialize()
      },
      /**
       * @description show the create modal when click the detail on calendar view creation modal
       */
      showCreateModalWithData(newEvent) {
        this.selectedTaskId = null
        this.visibleActivityModal = true
        this.$refs.activityWidget.initializeOnClickDetail(newEvent)
      },
      /**
       * @description when click the add button on calendar view creation modal
       * @param newEvent
       */
      addBriefActivity(newEvent) {
        this.selectedTaskId = null
        this.$refs.activityWidget.initializeOnClickDetail(newEvent)
        this.$refs.activityWidget.submit(this.selectedTaskId)
      },
      showUpdateModal(item) {
        this.$refs.activityWidget.initializeWithEntity(item)
        this.visibleActivityModal = true
        this.selectedTaskId = item.id
      },
      deleteTask(item) {
        this.tableShowLoading = true
        axios.delete(`task/${item.id}`)
          .then(() => {
            this.tableData = this.tableData && this.tableData.filter(entity => entity.id !== item.id)
            this.$refs.calendar.events = this.$refs.calendar.events && this.$refs.calendar.events.filter(entity => entity.id !== item.id)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.tableShowLoading = false
          })
      },
      hideModalOnCalendar() {
        this.$refs.calendar.hideModal('activity-modal')
        this.$refs.calendar.hideModal('detail-modal')
      }
    },
    watch: {
      // when modal closed, initialize the activity modal fields
      visibleActivityModal: function (val) {
        if (!val) {
          this.$refs.activityWidget.initialize()
        }
      },
      activeTab(val) {
        console.log(val)
        this.hideModalOnCalendar()
      }
    }
  }
</script>