<template>
  <div id="wrapper">
    <div class="text-center calendar-title-bar">
      <CButton
        @click="calendarPrevious()"
        style="box-shadow: none"
      >
        <CIcon
          name="cil-arrow-circle-left"
        />
      </CButton>
      <datepicker
        :language="datepickerLangGerman"
        :monday-first="true"
        v-model="selectedDate"
        format="dd.MM.yyyy"
      />
      <CButton
        style="box-shadow: none"
        @click="calendarNext()"
      >
        <CIcon
          name="cil-arrow-circle-right"
        />
      </CButton>
    </div>
    <vue-cal
      ref="vuecal"
      id="calendar"
      hide-view-selector
      :selected-date="selectedDate"
      :time-from="0"
      :time-to="24 * 60"
      :disable-views="['years', 'year', 'month', 'day']"
      editable-events
      :events="events"
      cell-contextmenu
      :snap-to-time="15"
      @cell-click="onEvent('cell-click', $event)"
      @event-duration-change="onEventChange('event-duration-change', $event)"
      @event-title-change="onEventChange('event-title-change', $event)"
      @event-drop="onEventChange('event-drop', $event)"
      :on-event-click="onEventClick"
      today-button
      locale="de"
    >
    </vue-cal>
    <div
      id="activity-modal"
    >
      <div>
        <CButton
          size="sm"
          color="dark"
          :variant="type === item.value ? '' : 'outline'"
          v-for="(item, index) of activityTypes"
          v-on:click="type = item.value"
          v-bind:key="index"
          v-c-tooltip="item.text"
        >
          <CIcon :name="item.icon"/>
        </CButton>
      </div>

      <div class="mt-2">
        <CInput v-model="title" class="mb-2"/>
        <label class="text-danger" v-if="errors['title']">
          Bitte geben Sie den Titel ein
        </label>
      </div>

      <div class="mb-2">
        <span>{{newEvent && formatTime(newEvent.start)}} - {{newEvent && formatTime(newEvent.end)}}</span>
      </div>

      <div>
        <CButton class="btn btn-primary" @click="onClickDetail">
          Details...
        </CButton>
        &nbsp;&nbsp;
        <CButton class="btn btn-success" @click="addActivity()">
          Hinzufügen
        </CButton>
      </div>
    </div>

    <div
      id="detail-modal"
    >
      <div class="activity-title">
        <CIcon
          :name="getIconFromActivityType(selectedActivity && selectedActivity.type)"
        />
        {{selectedActivity && selectedActivity.originalTitle}}
      </div>

      <div class="my-2">
        {{formatDuration(selectedActivity && selectedActivity.startTimeObj, selectedActivity && selectedActivity.endTimeObj)}}
      </div>

      <div class="d-flex justify-content-between align-items-baseline">
        <CButton
          size="sm"
          @click="onClickDelete()"
        >
          <CIcon name="cil-trash" class="text-danger"/>
        </CButton>
        <CInputCheckbox
          label="Als erledigt markieren"
          :checked="selectedActivity && selectedActivity.done"
          @change="onChangeStatus($event)"
        />
        <CButton
          size="sm"
          class="btn btn-primary"
          @click="onClickEdit()"
        >
          Edit
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
  import VueCal from 'vue-cal'
  import 'vue-cal/dist/vuecal.css'
  import 'vue-cal/dist/i18n/de.es.js'
  import 'vue-cal/dist/drag-and-drop.es.js'
  import {activityTypes} from "../../config/constant"
  import moment from 'moment'
  import {de} from 'vuejs-datepicker/dist/locale'
  import Datepicker from 'vuejs-datepicker'
  import axios from "axios"
  import commons from "../../commons";

  export default {
    props: [
      'showCreateModalWithData',
      'showUpdateModal',
      'addBriefActivity',
      'deleteActivity',
      'applyParentUpdate'
    ],
    components: {
      VueCal,
      Datepicker,
    },
    computed: {
      newEvent() {
        return this.events && this.events.find(event => event.id === '-1')
      }
    },
    mounted() {
      this.selectedDate = new Date()
    },
    watch: {
      title(value) {
        const index = this.events && this.events.findIndex(event => event.id === '-1')
        if (index !== -1) {
          this.events[index]['title'] = this.getTitleFromActivityType(this.events[index]['type']) + ' - ' + value
          this.events[index]['originalTitle'] = value
        }

        if (value && (value.trim() !== '')) {
          this.errors['title'] = null
        } else {
          this.errors['title'] = true
        }
      },
      type(value) {
        const index = this.events && this.events.findIndex(event => event.id === '-1')
        if (index !== -1) {
          this.events[index]['type'] = value
          this.events[index]['title'] = this.getTitleFromActivityType(value) + ' - ' + this.events[index]['originalTitle']
        }
      },
      selectedDate(value) {
        this.week = new Date(value).getWeek()
      },
      // when changed week, load the activities according the date range
      week(value) {
        const newDate = new Date(this.selectedDate)
        let first = newDate.getDate() - (newDate.getDay() - 1) // First day is the day of the month - the day of the week
        // when Sunday
        if (newDate.getDay() === 0) {
          first = newDate.getDate() - (newDate.getDay() + 6) // First day is the day of the month - the day of the week
        }
        const last = first + 7; // last day is the first day + 6

        const firstDay = new Date(newDate.setDate(first))
        firstDay.setHours(0)
        firstDay.setMinutes(0)
        firstDay.setSeconds(0)
        const lastDay = new Date(newDate.setDate(last))
        lastDay.setHours(0)
        lastDay.setMinutes(0)
        lastDay.setSeconds(0)

        this.getActivitiesOnRange(firstDay, lastDay)
      }
    },
    data: () => ({
      datepickerLangGerman: de,
      selectedDate: null,
      week: null,
      errors: {
        title: null,
      },
      events: [],
      activityTypes: activityTypes,
      type: activityTypes && activityTypes[0].value,
      title: '',
      done: false,
      selectedActivity: null,
    }),
    methods: {
      /**
       * @description when click the calendar cell, show the modal
       * @param type
       * @param e
       */
      onEvent(type, e) {
        if (!e) {
          return
        }
        const index = this.events && this.events.findIndex((event) => event.class === 'new')
        if (index !== -1) {
          this.events.splice(index, 1)
        }
        const start = moment(e).format('YYYY-MM-DD HH:mm')
        const end = moment(e).add(60, 'minutes').format('YYYY-MM-DD HH:mm')
        this.type = activityTypes && activityTypes[0].value
        this.title = ''
        this.events.push({
          id: '-1',
          start,
          end,
          startTimeObj: new Date(e),
          endTimeObj: moment(e).add(60, 'minutes').toDate(),
          title: this.getTitleFromActivityType(this.type) + ' -',
          originalTitle: '',
          class: 'new',
          type: this.type,
          titleEditable: false,
        })

        this.showModal(e, 'activity-modal')
      },
      /**
       * @description show the modal that can input the title and select the activity type
       * @param e
       * @param id: tag id
       */
      showModal(e, id) {
        this.hideModal('activity-modal')
        this.hideModal('detail-modal')

        const calendarWidth = document.getElementById('calendar').clientWidth - 40
        const dayWidth = Math.round(calendarWidth / 7)
        let day = (new Date(e).getDay() + 6) % 7
        let hours = new Date(e).getHours()
        const top = (document.getElementById('calendar').offsetTop)
        const left = (document.getElementById('calendar').offsetLeft + 42)
        const attach = document.getElementById(id)

        if (hours >= 21) {
          attach.style.top = '' + (top + ((hours - 3) * 40) + 80) + 'px'
        } else {
          attach.style.top = '' + (top + (hours * 40) + 80) + 'px'
        }
        if (day >= 5) {
          day -= 1
          attach.style.left = '' + (left + (dayWidth * day) - (300 - dayWidth)) + 'px'
        } else {
          day += 1
          attach.style.left = '' + (left + (dayWidth * day)) + 'px'
        }

        attach.style.display = 'block'
      },
      hideModal(id) {
        const attach = document.getElementById(id)
        attach.style.display = 'none'
      },
      /**
       * @description when click the add button on brief modal
       */
      addActivity() {
        if (!this.title && this.title.trim() === '') {
          this.errors['title'] = true
          return
        }
        const data = JSON.parse(JSON.stringify(this.newEvent))
        data['title'] = data['originalTitle']
        this.addBriefActivity(data)
        this.hideModal('activity-modal')
      },
      /**
       * @description when change the duration of the event
       * @param type
       * @param e
       */
      onEventChange(type, e) {
        const id = e.event.id

        const index = this.events && this.events.findIndex(event => (event.id === id))
        if (index !== -1) {
          this.events[index]['title'] = this.getTitleFromActivityType(this.events[index]['type']) + ' - ' + e.event.originalTitle
          this.events[index]['start'] = moment(e.event.start).format('YYYY-MM-DD HH:mm')
          this.events[index]['startTimeObj'] = new Date(e.event.start)
          this.events[index]['end'] = moment(e.event.end).format('YYYY-MM-DD HH:mm')
          this.events[index]['endTimeObj'] = new Date(e.event.end)

          if (this.events[index]['id'] !== '-1') {
            const id = this.events[index]['id']
            axios.put(`/task/${id}/duration`, {
              startTime: this.events[index]['startTimeObj'],
              endTime: this.events[index]['endTimeObj'],
            })
              .then(response => {
                this.applyParentUpdate(response.data)
              })
              .catch(error => {
                console.log(error)
              })
              .finally()
          }
        }
      },
      onEventClick(event) {
        if (event.id !== '-1') {
          // hide new event
          this.events = this.events.filter(entity => entity.id !== '-1')

          this.showModal(event.startTimeObj, 'detail-modal')
          this.selectedActivity = event
        }
      },
      /**
       * @description show create modal when click the detail button
       */
      onClickDetail() {
        const data = JSON.parse(JSON.stringify(this.newEvent))
        data['title'] = data['originalTitle']
        this.showCreateModalWithData(data)
        this.hideModal('activity-modal')
      },
      onClickDelete() {
        if (this.selectedActivity) {
          this.hideModal('detail-modal')
          this.deleteActivity(this.selectedActivity)
        }
      },
      onClickEdit() {
        if (this.selectedActivity) {
          const data = JSON.parse(JSON.stringify(this.selectedActivity))
          data['title'] = data['originalTitle']
          this.showUpdateModal(data)
          this.hideModal('detail-modal')
        }
      },
      onChangeStatus(e) {
        if (this.selectedActivity) {
          const checked = e.target.checked
          axios.put(`/task/${this.selectedActivity.id}/status`, {
            done: checked,
          })
            .then(response => {
              this.applyParentUpdate(response.data)
            })
            .catch(error => {
              console.log(error)
            })
            .finally()
        }
      },
      formatTime(d) {
        return moment(d).format('HH:mm')
      },
      // when click next on calendar
      calendarNext() {
        this.$refs.vuecal.next()
        const afterWeekDate = this.selectedDate.getDate() + 7
        const afterWeek = new Date(this.selectedDate)
        afterWeek.setDate(afterWeekDate)
        this.selectedDate = afterWeek
      },
      calendarPrevious() {
        this.$refs.vuecal.previous()
        const beforeWeekDate = this.selectedDate.getDate() - 7
        const beforeWeek = new Date(this.selectedDate)
        beforeWeek.setDate(beforeWeekDate)
        this.selectedDate = beforeWeek
      },
      getActivitiesOnRange(startTime, endTime) {
        const data = {
          startTime,
          endTime,
        }
        axios.post(`/task/date`, data)
          .then(response => {
            this.events = []
            response && response.data && response.data.map(item => {
              const event = {
                id: item.id,
                start: moment(item.startTime).format('YYYY-MM-DD HH:mm'),
                end: moment(item.endTime).format('YYYY-MM-DD HH:mm'),
                startTimeObj: new Date(item.startTime),
                endTimeObj: new Date(item.endTime),
                title: this.getTitleFromActivityType(item.type) + ' - ' + item.title,
                originalTitle: item.title,
                type: item.type,
                content: '',
                class: 'other',
                titleEditable: false
              }
              this.events.push({...commons.flatObject(item), ...event})
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      applyUpdate(item) {
        const index = this.events && this.events.findIndex(event => event.id === item.id)

        if (index !== -1) { // if updated
          this.events.splice(index, 1)
        }

        const event = {
          id: item.id,
          start: moment(item.startTime).format('YYYY-MM-DD HH:mm'),
          end: moment(item.endTime).format('YYYY-MM-DD HH:mm'),
          startTimeObj: new Date(item.startTime),
          endTimeObj: new Date(item.endTime),
          originalTitle: item.title,
          title: this.getTitleFromActivityType(item.type) + ' - ' + item.title,
          type: item.type,
          content: '',
          class: 'other',
          titleEditable: false
        }
        this.events.push({...commons.flatObject(item), ...event})
        this.events = this.events.filter(event => event.id !== '-1')

        if ((this.selectedActivity && this.selectedActivity.id) === item.id) {
          this.selectedActivity = {...commons.flatObject(item), ...event}
        }
      },
      getIconFromActivityType(type) {
        const item = this.activityTypes && this.activityTypes.find(entity => entity.value === type)
        return item && item.icon
      },
      getTitleFromActivityType(type) {
        const item = this.activityTypes && this.activityTypes.find(entity => entity.value === type)
        return item && item.text
      },
      formatToLocale(d) {
        moment.locale('de')
        return moment(d).format('LLL')
      },
      formatDuration(start, end) {
        const startDate = new Date(start)
        const endDate = new Date(end)
        if (
          (startDate.getFullYear() === endDate.getFullYear()) &&
          (startDate.getMonth() === endDate.getMonth()) &&
          (startDate.getDate() === endDate.getDate())
        ) {
          return this.formatToLocale(start) + ' - ' + moment(end).format('HH:mm')
        } else {
          return this.formatToLocale(start) + ' - ' + this.formatToLocale(end)
        }
      }
    }
  }
</script>

<style>
  .vuecal__event.other {
    background-color: rgb(74 138 230);
    color: white;
  }

  .vuecal__event.new {
    background-color: #05B9AE;
    color: white;
  }

  #activity-modal {
    position: absolute;
    width: 300px;
    background: #fff;
    padding: 10px;
    z-index: 10;
    border-radius: 5px;
    display: none;
    border: #e6e6e6 2px solid;
  }

  .calendar-title-bar {
    justify-content: center;
    font-size: 18px;
  }

  .vdp-datepicker input {
    height: 33px;
    width: 100%;
    border: 1px #d8dbe0 solid;
    padding-left: 13px;
  }

  .vdp-datepicker input:focus {
    border: 1px #ccc7f6 solid;
  }

  #detail-modal {
    position: absolute;
    width: 320px;
    background: #fff;
    padding: 10px;
    z-index: 10;
    border-radius: 5px;
    border: #e6e6e6 2px solid;
    display: none;
  }

  .activity-title {
    font-size: 18px;
    font-weight: bold;
  }
</style>
